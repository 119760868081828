import '../App.css';
import { useSDITags } from '../TagTable';

type SpotProps = {
   info?: {authorizationId: string, occupied: boolean, labels: string[] }
   x: number
   y: number 
}

const Spot = ({ info, x, y }: SpotProps) => {
  const { data } = useSDITags();
  if (!info) { return null }
  const { authorizationId, occupied, labels } = info
  if (occupied === null) {
    return (<div className="spot unknown" style={{ bottom: y, left: x }} />)
  }

  console.log((data || []).map(s => (s.sdi_id || '').toLowerCase()))
  if (authorizationId) { console.log(info); }

  if (!(data || []).map(s => (s.sdi_id || '').toLowerCase()).includes((authorizationId || '').toLowerCase()) && occupied && labels && labels.includes('VIP')) {
    return (<i style={{ bottom: y, left: x }} className="unauthorized fa-solid fa-circle-exclamation"></i>)
  }
  if (occupied) {
    return (<div className="spot occupied" style={{ bottom: y, left: x }} />)
  }

  return (<div className="spot" style={{ bottom: y, left: x }} />)
}

export const Floor = ({ data }: any) => {
  return (
    <div className="App">
      <Spot info={data.find((item: any) => item.customId === '1')} x={55} y={513}/>
      <Spot info={data.find((item: any) => item.customId === '2')} x={55} y={460}/>
      <Spot info={data.find((item: any) => item.customId === '3')} x={55} y={416} />
      <Spot info={data.find((item: any) => item.customId === '4')} x={55} y={372} />
      <Spot info={data.find((item: any) => item.customId === '5')} x={55} y={328} />
      <Spot info={data.find((item: any) => item.customId === '6')} x={55} y={284}/>
      <Spot info={data.find((item: any) => item.customId === '7')} x={55} y={240} />
      <Spot info={data.find((item: any) => item.customId === '8')} x={55} y={196} />
      <Spot info={data.find((item: any) => item.customId === '9')} x={55} y={152} />
      <Spot info={data.find((item: any) => item.customId === '10')} x={55} y={108}/>
      <Spot info={data.find((item: any) => item.customId === '11')} x={156} y={36} />
      <Spot info={data.find((item: any) => item.customId === '12')} x={200} y={36} />
      <Spot info={data.find((item: any) => item.customId === '13')} x={243} y={36} />
      <Spot info={data.find((item: any) => item.customId === '14')} x={288} y={36} />
      <Spot info={data.find((item: any) => item.customId === '15')} x={331} y={36}/>
      <Spot info={data.find((item: any) => item.customId === '16')} x={331} y={202} />
      <Spot info={data.find((item: any) => item.customId === '17')} x={287} y={202} />
      <Spot info={data.find((item: any) => item.customId === '18')} x={243} y={202} />
      <Spot info={data.find((item: any) => item.customId === '19')} x={199} y={202} />
      <Spot info={data.find((item: any) => item.customId === '20')} x={210} y={372}/>
      <Spot info={data.find((item: any) => item.customId === '21')} x={210} y={416} />
      <Spot info={data.find((item: any) => item.customId === '22')} x={210} y={460} />
      <Spot info={data.find((item: any) => item.customId === '23')} x={210} y={513} />
      <Spot info={data.find((item: any) => item.customId === '24')} x={465} y={381} />
      <Spot info={data.find((item: any) => item.customId === '25')} x={552} y={381} />
      <Spot info={data.find((item: any) => item.customId === '26')} x={596} y={381} />
      <Spot info={data.find((item: any) => item.customId === '27')} x={641} y={381} />
      <Spot info={data.find((item: any) => item.customId === '28')} x={684} y={381} />
      <Spot info={data.find((item: any) => item.customId === '29')} x={728} y={381} />
      <Spot info={data.find((item: any) => item.customId === '30')} x={772} y={381} />
      <Spot info={data.find((item: any) => item.customId === '31')} x={816} y={381} />
      <Spot info={data.find((item: any) => item.customId === '32')} x={859} y={381} />
      <Spot info={data.find((item: any) => item.customId === '33')} x={859} y={202} />
      <Spot info={data.find((item: any) => item.customId === '34')} x={817} y={202} />
      <Spot info={data.find((item: any) => item.customId === '35')} x={772} y={202} />
      <Spot info={data.find((item: any) => item.customId === '36')} x={728} y={202} />
      <Spot info={data.find((item: any) => item.customId === '37')} x={684} y={202} />
      <Spot info={data.find((item: any) => item.customId === '38')} x={641} y={202} />
      <Spot info={data.find((item: any) => item.customId === '39')} x={859} y={36} />
      <Spot info={data.find((item: any) => item.customId === '40')} x={817} y={36} />
      <Spot info={data.find((item: any) => item.customId === '41')} x={772} y={36} />
      <Spot info={data.find((item: any) => item.customId === '42')} x={728} y={36}/>
    </div>
  );
}
