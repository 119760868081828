import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {
  useQuery,
} from 'react-query'

const getSDITags = () => fetch('https://apidoc.nwave.io/sdidemo/sdi_tags', {
  method: 'GET',
})
.then(response => response.json())
.catch(error => {
    console.error('Error fetching parking data:', error);
});

export const useSDITags = () => {
  return useQuery<{ sdi_id: string; projects: string[]; owner_name: string }[]>('sdi-tags', getSDITags)
}

const setSDITag = (data: any) => fetch('https://apidoc.nwave.io/sdidemo/sdi_tag', {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data)
})
.then(response => response.json())
.catch(error => {
    console.error('Error creating tag:', error);
});

const deleteSDITag = (id: any) => fetch(`https://apidoc.nwave.io/sdidemo/sdi_tag/${id}`, {
  method: 'DELETE',
  headers: { 'Content-Type': 'application/json' },
})
.then(response => response.json())
.catch(error => {
    console.error('Error deleting tag:', error);
});

export const TagsTable = () => {
  const query = useSDITags()
  const { data } = query;
  const [tagId, setTagId] = useState('')
  const [owner, setOwner] = useState('')

  return (<>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Owner</TableCell>
            <TableCell>Tag ID</TableCell>
            <TableCell>Project</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((tag) => (
            <TableRow key={tag.sdi_id}>
              <TableCell>{tag.owner_name}</TableCell>
              <TableCell>{tag.sdi_id}</TableCell>
              <TableCell>{tag.projects.join(',')}</TableCell>
              <TableCell>
                <IconButton aria-label="delete" onClick={() => { deleteSDITag(tag.sdi_id).then(() => { query.refetch(); }) }}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Box marginTop="32px" bgcolor="#E5E5E5" width="100%">
      <Box display="flex" padding="16px" justifyContent="space-between" width="600px">
        <TextField
          style={{ backgroundColor: '#FFF' }}
          value={tagId}
          onChange={e => { 
            const v = e.target.value
            const isValid = /^[0-9A-Fa-f]*$/.test(v)
            if (isValid && v.length < 9) {
              setTagId(e.target.value)
            }
          }}
          label="SDI Tag ID"
          variant="outlined"
        />
        <TextField
          style={{ backgroundColor: '#FFF' }}
          value={owner}
          onChange={e => { 
            const v = e.target.value
            const isValid = /^[0-9A-Za-z\s]*$/.test(v)
            if (isValid && v.length < 51) {
               setOwner(e.target.value) 
            }
          }}
          label="Owner name"
          variant="outlined"
        />
        <Button variant="contained" color="primary" onClick={() => 
            setSDITag({ sdi_id: tagId, owner_name: owner, projects: ["DIFC Demo"] }).then(() => { setTagId(''); setOwner(''); query.refetch() })
          }
        >
          Add Tag
        </Button>
      </Box>
    </Box>
  </>);
};
