import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  QueryClientProvider, QueryClient
} from 'react-query'
import "./index.css";

import { AuthPage } from './pages/Auth';
import { Floors } from './pages/Floors';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient()

const App = () => {
  const isAuth = localStorage.getItem("isAuth");

  if (!isAuth) { return <AuthPage /> }
  return <Floors />
}

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);
