import { useEffect } from 'react';
import './App.css';
import { useSpotsStatuses } from './api';
import { Floor } from './Floors/floor';

export const FloorMap = ({ floor }: any) => {
  const { data, newUnautorized, initStatus } = useSpotsStatuses();

  useEffect(() => {
    if (Notification.permission === 'granted' && initStatus === 'fetched' && newUnautorized.length > 0) {
        new Notification(`Parking ${newUnautorized.length > 1 ? `spots ${newUnautorized.join(',')}` : `spot ${newUnautorized[0]}`} was occupied by an unauthorized driver`);
    } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then();
    }
  }, [initStatus, newUnautorized])

  // @ts-ignore
  const floors = data.reduce((res, item) => {
    if (!res[item.floor_number]){
      res[item.floor_number] = [item]
    } else {
      res[item.floor_number] = [ ...res[item.floor_number], item ]
    }
    return res
  }, {} as { [x: number]: {} })

  return (
    <div className="App">
      <Floor data={floors[floor] ? floors[floor] : []} />
    </div>
  );
}
