import { useState, useEffect, useMemo } from 'react'
import {
  useQuery,
} from 'react-query'
import { useSDITags } from './TagTable';

const normalize = (data: any) => data
  .map((item: any) => ({ ...item, authorizationId: item.authorization?.id, occupied: item.occupied, customId: item.custom_id, network_id: item.network_id }))

const getVipSpotStatuses = () => fetch('https://api.nwave.io/analytics/v1/realtime/state?zone_id=951&limit=200', {
  method: 'GET',
  headers: {
      'X-Auth-Token': '6feoguxSq7ZbzLgpAAQmnZVewAAoPZX8'
  }
})
.then(response => response.json())
.then(data => normalize(data.data))
.catch(error => {
    console.error('Error fetching parking data:', error);
});


export const useSpotsStatuses = () => {
  const { data } = useSDITags();
  const [currentUnautorized, setCurrentUnautorized] = useState<number[]>([]) // (?_)
  const [newUnautorized, setNewUnautorized] = useState<number[]>([])
  const [initStatus, setInitStatus] = useState('init')
  const query = useQuery('spots-statuses', getVipSpotStatuses, { refetchInterval: 5000 })

  console.log('query.data', query.data);

  const unautorized = useMemo(() => (query.data || [])
    .filter((p: any) => !(data || []).map(s => (s.sdi_id || '').toLowerCase()).includes((p.authorizationId || '').toLowerCase()) &&
    p.occupied && p.labels &&
    p.labels.includes('VIP')
  ), [query.data, data])

  useEffect(() => {
    if (initStatus === 'init' && query.isSuccess) {
      setInitStatus('fetched')
      setCurrentUnautorized(unautorized);
    }
    if (initStatus === 'fetched') {
        setCurrentUnautorized(unautorized);
        const newUnauth = unautorized.filter((u: any) => !currentUnautorized.includes(u))
        setNewUnautorized(newUnauth)
      }
  }, [unautorized, currentUnautorized])

  console.log('unautorized', currentUnautorized);
  console.log('newUnauth', newUnautorized);

  return {
    data: query.data || [],
    //@ts-ignore
    newUnautorized: newUnautorized.map(n => n.custom_id),
    unautorizedByFloors: unautorized.map((u: any) => u.floor_number) || [],
    initStatus,
    unautorized,
  };
}