import { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import BusinessIcon from '@mui/icons-material/Business';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LogoutIcon from '@mui/icons-material/Logout';
import { TagsTable } from './TagTable'
import { FloorMap } from './Map'
import { useSpotsStatuses } from './api';

const drawerWidth = 240;

const projects = [{
  name: 'DIFC Demo',
  id: 332,
}]

export const Floors = () => {
  const [project, setProject] = useState<number | string>('')
  const { unautorizedByFloors } = useSpotsStatuses();

  const [contentType, setContentType] = useState<string | number>(0)

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" noWrap component="div">BLE tags management</Typography>
            <FormControl style={{ color: '#FFFFFF' }} variant="standard" sx={{ m: 1, minWidth: 220 }}>
              <Select
                sx={{
                  '& .MuiSelect-select': {
                    color: '#FFF',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#FFF',
                  },
                  '& .MuiSelect-select::before': {
                    borderColor: '#FFF',
                  }
                }}
                displayEmpty
                value={projects[0].id}
                placeholder='Project'
                onChange={p => setProject(p.target.value)}
              >
                <MenuItem disabled value="">
                  Project
                </MenuItem>
                {projects.map(p =>  <MenuItem value={p.id}>{p.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {[0,1,2,3].map((key, index) => (
              <ListItem key={key} disablePadding>
                <ListItemButton style={{ display: 'flex', justifyContent: 'end' }} onClick={() => setContentType(key)} selected={key === contentType}>
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary={`Floor ${key}`} />
                  {unautorizedByFloors.includes(key) &&  <i style={{ right: '16px' }} className="unauthorized fa-solid fa-circle-exclamation"></i>}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
              <ListItem key="Tag managment" disablePadding>
                <ListItemButton selected={'TAG_MANAGER' === contentType} onClick={() => setContentType('TAG_MANAGER')}>
                  <ListItemIcon>
                    <LocalOfferIcon />
                  </ListItemIcon>
                  <ListItemText primary="Tag managment" />
                </ListItemButton>
              </ListItem>
          </List>
          <List style={{ position: 'absolute', bottom: 0, width: '100%' }}>
              <ListItem key="Logout" disablePadding>
                <ListItemButton onClick={() => { localStorage.setItem("isAuth", ''); window.location.reload(); }}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {contentType !== 'TAG_MANAGER' && <FloorMap floor={contentType} />}
        {contentType === 'TAG_MANAGER' && <TagsTable />}
      </Box>
    </Box>
  );
}
